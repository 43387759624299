import React from 'react';
import 'pages/MainPage/MainPage.sass';
import KioskBGImage from 'assets/KioskBG.png';
import Afisha1 from 'assets/Afisha1.png';
import Afisha2 from 'assets/Afisha2.png';
import { Link } from 'react-router-dom';

function MainPage(): React.ReactElement {
    return (
        <main className="main-page">
            <h1>ИКСС.КИОСК</h1>
            <h2>ИП «Собака, не кот»</h2>
            <img className="background-image" src={KioskBGImage} alt="" />
            <img src={Afisha1} alt="" className="afisha afisha-1" />
            <img src={Afisha2} alt="" className="afisha afisha-2" />

            <Link to="anketa">
                <button type="button">
                    Постучаться и&nbsp;забрать <br /> выпуск
                </button>
            </Link>
        </main>
    );
}

export default React.memo(MainPage);
