import { FacultyData } from 'pages/Anketa/types';
import IKSS from 'assets/IKSS.png';
import IM from 'assets/IM.png';
import ISiT from 'assets/ISiT.png';
import SCT from 'assets/SCT.png';
import VUC from 'assets/VUC.png';
import KT from 'assets/KT.png';
import RTS from 'assets/RTS.png';
import TSEUBI from 'assets/TSEUBI.png';
import INO from 'assets/INO.png';
import FP from 'assets/FP.png';

const facultyData: FacultyData = {
    ИКСС: {
        text: `– ШОК! На иксс поступила змея, в честь этого в названии добавили еще парочку ссссс.\n
– В семье пополнение! Еще 14 направлений…\n
– Для факультета построили отдельное здание…\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/j487mZ1/IKSS.png',
        image: IKSS,
    },
    ИСиТ: {
        text: `– ШОК!  Наш мальчик наконец-то стал взрослым! Вот ему и 18…\n
– Факультет просит забрать его скорей и увезти за сто морей…\n
– и кто? и сит! ученые выяснили кто такой этот сит!\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/5s9vN0g/ISiT.png',
        image: ISiT,
    },
    РТС: {
        text: `– ШОК! Море волнуется раз, море волнуется два, море волнуется три… успокоительное не спасает… \n
– Волны еще волнуются…\n
– Радиоволны переименовали из-за слишком волнительного названия.  \n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/g7GgThV/RTS.png',
        image: RTS,
    },
    СЦТ: {
        text: `– ШОК! Переобулись…Факультет СЦТ переименуют в ГФ\n
– Гендерное неравенство…На 10 девчонок по статистике двое ребят\n
– На специальности ЗР добавят новое направление - Язык фактов.\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/pz7GY5V/SCT.png',
        image: SCT,
    },
    ЦЭУБИ: {
        text: `– ШОК! Лучшей дипломной работой признали исследование на тему “Марафон желаний или как я заработал первый миллион на Мойке”\n
– Елена Блиновская посетила студентов ЦЭУБИ с лекцией про Успешный Успех\n
– Студентов ЦЭУБИ перевели на ночное обучение, чтобы соответствовать символу факультета.\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/6rkyc4n/TSEUBI.png',
        image: TSEUBI,
    },
    ФП: {
        text: `– ШОК! Студенты ффп открыли 51 оттенок серого.\n
– Студенты одного из факультетов выступили с инициативой подготовить фундамент для будущего спортивного комплекса университета\n
– Факультет фундаментальной подготовки вошел в состав университета на этапе котлована\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/TrcggKx/FP.png',
        image: FP,
    },
    ИНО: {
        text: `– ШОК! Институту непрерывного образования пришлось срочно прерваться на…\n
– Студент ино непрерывно писал конспект, и с ним случилось ужасное.\n
– Раскрыта тайна названия ИНО - на 1 курс зачислен инопланетянин \n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/nCYPtMW/INO.png',
        image: INO,
    },
    ВУЦ: {
        text: `– ШОК! В недавнем интервью Арнольд Шварценеггер признался что всегда мечтал учится на ВУЦ в Бонче!\n
– Хаки отменяется, теперь в моде оливковый!\n
– Студент ВУЦ на первую стипендию купил Гелик!\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/MydBWt2/VUC.png',
        image: VUC,
    },
    КТ: {
        text: `– ШОК! В Колледже добавят еще 4 этажа!\n
– Лифт обещают установить до 2030 года!\n
– Но это не точно\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/sC1GyPJ/KT.png',
        image: KT,
    },
    ИМ: {
        text: `– ШОК! 100% студентов магистратуры съехали от родителей.\n
– С 2025 года каждый магистрант сможет оформить пенсионное удостоверение\n
– Бакалаврский бунт: Почему все ИМ, а не НАМ\n
#ИКССКиоск
`,
        url: 'https://i.ibb.co/x28GX0v/IM.png',
        image: IM,
    },
};

export default facultyData;
