import React from 'react';
import MagazinePage from 'pages/Anketa/MagazinePage';
import { Answer, Score } from './types';
import questions from './data';
import Anketa from './QuetionPage';

export default function AnketaOutlet(): React.ReactElement {
    const [currentQuestion, setCurrentQuestion] = React.useState(questions[0]);
    const [completed, setCompleted] = React.useState(false);
    const [score, setScore] = React.useState<Score>({});

    function handleAnswer(answer: Answer) {
        setScore(state => {
            const newState = { ...state };
            answer.score.faculties.forEach(facultyName => {
                if (newState[facultyName] === undefined)
                    newState[facultyName] = answer.score.amount;
                else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    newState[facultyName] += answer.score.amount;
                }
            });
            return newState;
        });
        setCurrentQuestion(state => {
            const questionId = questions.indexOf(state);
            if (questionId + 1 === questions.length) {
                setCompleted(true);
                return state;
            }
            return questions[questionId + 1];
        });
    }
    if (completed) return <MagazinePage score={score} />;
    return (
        <Anketa
            question={currentQuestion}
            selectAnswer={answer => handleAnswer(answer)}
        />
    );
}
