import React from 'react';
import 'pages/Anketa/QuetionPage/Anketa.sass';
import { Answer, Question } from 'pages/Anketa/types';

export interface AnketaProps {
    question: Question;
    selectAnswer: (answer: Answer) => void;
}

function Anketa({ question, selectAnswer }: AnketaProps): React.ReactElement {
    const [selectedAnswer, setSelectedAnswer] = React.useState<Answer>();
    const { answers } = question;
    return (
        <main className="anketa-page">
            <div className="chip">
                <p>СПЕЦВЫПУСК</p>
                <p className="orange">в ИКСС.КИОСК</p>
            </div>
            <div className="header">
                <h1>АНКЕТА</h1>
                <h1>ДЛЯ ДРУЗЕЙ</h1>
            </div>

            <div className="question">
                <h2>{question.text}</h2>

                <div className="answers">
                    {answers.map((answer, id) => {
                        return (
                            <div className="answer" key={id}>
                                <input
                                    onChange={() => setSelectedAnswer(answer)}
                                    checked={selectedAnswer === answer}
                                    id={`checkbox-${id}`}
                                    type="checkbox"
                                />{' '}
                                <label htmlFor={`checkbox-${id}`}>
                                    {answer.text}
                                </label>
                            </div>
                        );
                    })}
                </div>

                <div className="actions">
                    <button
                        disabled={!selectedAnswer}
                        type="button"
                        onClick={() => {
                            if (selectedAnswer) selectAnswer(selectedAnswer);
                        }}
                    >
                        Следующий вопрос
                    </button>
                </div>
            </div>
        </main>
    );
}

export default React.memo(Anketa);
