import React from 'react';

import { RouteObject } from 'react-router-dom';
import MainPage from 'pages/MainPage';
import AnketaOutlet from 'pages/Anketa/AnketaOutlet';

export const routes: RouteObject[] = [
    {
        element: <MainPage />,
        path: '',
    },
    {
        element: <AnketaOutlet />,
        path: 'anketa',
    },
];

export default routes;
