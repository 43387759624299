import { Question } from './types';

const questions: Question[] = [
    {
        text: 'Кем ты был в прошлой жизни?',
        answers: [
            {
                text: 'Коллектором',
                score: {
                    faculties: ['ЦЭУБИ'],
                    amount: 0.4,
                },
            },
            {
                text: 'Клоуном',
                score: {
                    faculties: ['ИСиТ'],
                    amount: 0.4,
                },
            },
            {
                text: 'Гениальным ученым',
                score: {
                    faculties: ['ИКСС'],
                    amount: 0.4,
                },
            },
            {
                text: 'Волной',
                score: {
                    faculties: ['РТС'],
                    amount: 0.4,
                },
            },
            {
                text: 'Камушком',
                score: {
                    faculties: ['СЦТ'],
                    amount: 0.4,
                },
            },
            {
                text: 'Эта моя первая жизнь',
                score: {
                    faculties: ['КТ'],
                    amount: 0.4,
                },
            },
            {
                text: 'Идущим к реке',
                score: {
                    faculties: ['ИМ'],
                    amount: 0.4,
                },
            },
            {
                text: 'Охранником',
                score: {
                    faculties: ['ВУЦ'],
                    amount: 0.4,
                },
            },
            {
                text: 'Фундаментом',
                score: {
                    faculties: ['ФП'],
                    amount: 0.4,
                },
            },
            {
                text: 'Гражданином РФ',
                score: {
                    faculties: ['ИНО'],
                    amount: 0.4,
                },
            },
        ],
    },
    {
        text: 'Как ты чаще проводишь свободное время?',
        answers: [
            {
                text: 'Гуляю по центру',
                score: {
                    faculties: ['ЦЭУБИ', 'КТ', 'ВУЦ'],
                    amount: 0.2,
                },
            },
            {
                text: 'Думаю, где я свернул не туда',
                score: {
                    faculties: ['ИКСС', 'ИСиТ', 'РТС'],
                    amount: 0.2,
                },
            },
            {
                text: 'Мониторю hh',
                score: {
                    faculties: ['ФП', 'СЦТ'],
                    amount: 0.2,
                },
            },
            {
                text: 'Смотрю сериальчики на работе',
                score: {
                    faculties: ['ИНО', 'ИМ'],
                    amount: 0.2,
                },
            },
        ],
    },
    {
        text: 'Если бы ты был местом, то каким?',
        answers: [
            {
                text: 'Холлом',
                score: {
                    faculties: ['СЦТ', 'ИНО'],
                    amount: 0.3,
                },
            },
            {
                text: 'Метро',
                score: {
                    faculties: ['ВУЦ', 'КТ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Портом',
                score: {
                    faculties: ['РТС', 'ЦЭУБИ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Пустым',
                score: {
                    faculties: ['ФП', 'ИСиТ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Колодцем',
                score: {
                    faculties: ['ИМ', 'ИКСС'],
                    amount: 0.3,
                },
            },
        ],
    },
    {
        text: 'Что ты делаешь утром первым делом?',
        answers: [
            {
                text: 'Вспоминаю алфавит',
                score: {
                    faculties: ['СЦТ', 'РТС'],
                    amount: 0.2,
                },
            },
            {
                text: 'Пытаюсь уснуть',
                score: {
                    faculties: ['ИСиТ', 'ФП'],
                    amount: 0.2,
                },
            },
            {
                text: 'Просыпаюсь',
                score: {
                    faculties: ['ИКСС', 'ИНО'],
                    amount: 0.2,
                },
            },
            {
                text: 'Проверяю котировки акций на Гонконгской бирже',
                score: {
                    faculties: ['ИМ', 'ЦЭУБИ'],
                    amount: 0.2,
                },
            },
            {
                text: 'Анжуманя и пресс качат',
                score: {
                    faculties: ['КТ', 'ВУЦ'],
                    amount: 0.2,
                },
            },
        ],
    },
    {
        text: 'Ты идешь по темному переулку и слышишь громкие шаги сзади. Твои действия?',
        answers: [
            {
                text: 'Бегит побэстрее',
                score: {
                    faculties: ['ВУЦ', 'ИНО'],
                    amount: 0.2,
                },
            },
            {
                text: 'Обернусь и скажу: «Привет, познакомимся?»',
                score: {
                    faculties: ['ИСиТ', 'СЦТ'],
                    amount: 0.2,
                },
            },
            {
                text: 'Мне страшно, Я еще маленький',
                score: {
                    faculties: ['ИМ', 'КТ'],
                    amount: 0.2,
                },
            },
            {
                text: 'Это я обычно хожу за людьми в этом переулке',
                score: {
                    faculties: ['ИКСС', 'РТС'],
                    amount: 0.2,
                },
            },
            {
                text: 'Сделаю вид, что звоню кому-то',
                score: {
                    faculties: ['ФП', 'ЦЭУБИ'],
                    amount: 0.2,
                },
            },
        ],
    },
    {
        text: 'Куда бы ты поехал в отпуск?',
        answers: [
            {
                text: 'Золотое кольцо России',
                score: {
                    faculties: ['ИНО', 'ВУЦ'],
                    amount: 0.2,
                },
            },
            {
                text: 'Аргентина',
                score: {
                    faculties: ['ИКСС', 'ИМ', 'КТ'],
                    amount: 0.2,
                },
            },
            {
                text: 'ОАЭ',
                score: {
                    faculties: ['ИСиТ', 'РТС'],
                    amount: 0.2,
                },
            },
            {
                text: 'Китай',
                score: {
                    faculties: ['СЦТ', 'ЦЭУБИ', 'ФП'],
                    amount: 0.2,
                },
            },
        ],
    },
    {
        text: 'Что всегда есть в твоей сумке?',
        answers: [
            {
                text: 'Ноутбук',
                score: {
                    faculties: ['ИСиТ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Зажигалочка',
                score: {
                    faculties: ['СЦТ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Гантели',
                score: {
                    faculties: ['ВУЦ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Телефон',
                score: {
                    faculties: ['РТС'],
                    amount: 0.3,
                },
            },
            {
                text: 'Цемент',
                score: {
                    faculties: ['ФП'],
                    amount: 0.3,
                },
            },
            {
                text: 'Деньги',
                score: {
                    faculties: ['ЦЭУБИ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Зарядка',
                score: {
                    faculties: ['ИНО'],
                    amount: 0.3,
                },
            },
            {
                text: 'Резюме',
                score: {
                    faculties: ['ИМ'],
                    amount: 0.3,
                },
            },
            {
                text: 'Осциллограф',
                score: {
                    faculties: ['ИКСС'],
                    amount: 0.3,
                },
            },
            {
                text: 'Какая сумка, у меня портфель!',
                score: {
                    faculties: ['КТ'],
                    amount: 0.3,
                },
            },
        ],
    },
];

export default questions;
