import React from 'react';
import 'pages/Anketa/MagazinePage/Magazine.sass';
import { Link } from 'react-router-dom';
import facultyData from 'pages/Anketa/MagazinePage/data';
import { Faculty, FacultyName, Score } from '../types';

export interface MagazineProps {
    score: Score;
}

function Magazine({ score }: MagazineProps): React.ReactElement {
    console.log(score);
    const url = 'https://iksskiosk.ru';
    const title = 'ИКСС.КИОСК';
    const [link, setLink] = React.useState(``);

    const [currentFaculty, setCurrentFaculty] = React.useState<Faculty>();

    React.useEffect(() => {
        let winnerFaculty = Object.keys(score)[0] as FacultyName;
        let winnerFacultyScore = score[winnerFaculty] as number;
        (Object.keys(score) as unknown as FacultyName[]).forEach(key => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (key in score && score[key] > winnerFacultyScore) {
                winnerFaculty = key;
                winnerFacultyScore = score[key] as number;
            }
        });
        setCurrentFaculty(facultyData[winnerFaculty]);
    }, [score]);

    React.useEffect(() => {
        setLink(
            `http://vk.com/share.php?url=${url}&title=${title}&comment=${`${encodeURIComponent(
                currentFaculty?.text || ''
            )}`}&image=${currentFaculty?.url}&noparse=true`
        );
    }, [currentFaculty?.text, currentFaculty?.url]);

    return (
        <main className="magazine-page">
            <h1>
                ИКСС.КИОСК <br />
            </h1>
            <h3>
                Поздравляем, <br />
                твой персональный <br />
                выпуск готов!
            </h3>
            <img src={currentFaculty?.image} alt="" />
            <div className="actions">
                <Link to="/">
                    <button type="button" className="orange">
                        вернуться в киоск
                    </button>
                </Link>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <button type="button">поделиться в ВК</button>
                </a>
                <p>среди поделившихся на странице будет проведен розыгрыш</p>
            </div>
        </main>
    );
}

export default React.memo(Magazine);
